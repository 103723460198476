<template>
  <ConfirmationDialog
    v-model="dialog"
    icon="far fa-trash"
    title="USER_MANAGEMENT.ROLES.ACTION_DELETE_TITLE"
    @confirm="deleteRole"
    tooltip="USER_MANAGEMENT.ROLES.ACTION_DELETE_TOOLTIP"
    confirmLabel="USER_MANAGEMENT.ROLES.ACTION_DELETE_BTN_LABEL"
    :loading="isLoading"
    :disableConfirm="hasErrors"
    @close="resetValidationErrors"
  >
    <BaseText>{{ $t("USER_MANAGEMENT.ROLES.ACTION_DELETE_CONTENT") }}</BaseText>
    <UmDeleteValidation :errors="validationErrors" v-if="hasErrors" />
  </ConfirmationDialog>
</template>

<script>
import ConfirmationDialog from "@components/ConfirmationDialog/";
import UserManagementDeleteValidation from "@mixins/UserManagementDeleteValidation.js";
import UmDeleteValidation from "@components/Management/UserManagementDeleteValidation.vue";
import { mapActions } from "vuex";

export default {
  name: "RoleDeleteAction",

  mixins: [UserManagementDeleteValidation],

  components: {
    ConfirmationDialog,
    UmDeleteValidation,
  },

  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },

  props: {
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },

    roleId: {
      type: Number,
      required: true,
      default: null,
    },

    buttonSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async deleteRole() {
      this.isLoading = true;

      try {
        await this.$axios.userManagement.deleteRole({ roleId: this.roleId });
        this.$emit("deleted");
        this.notify({
          type: "success",
          message: "USER_MANAGEMENT.ROLES.DELETE.SUCCESS",
        });
      } catch (err) {
        console.error(err);
        this.setValidationErrors(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
