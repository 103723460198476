<template>
  <ModuleSubPage fullWidth title="USER_MANAGEMENT.ROLES.TITLE">
    <BaseHasPermission :permissionNames="['role.read']">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col
              cols="auto"
              align-self="center"
              class="mr-3 mb-6 mb-md-0"
              v-if="hasPermission({ permissionNames: ['role.create'] })"
            >
              <RoleCreateDialog @reload="retrieveRoles" />
            </v-col>

            <v-spacer />

            <v-col cols="12" md="5" lg="4" xl="3" align-self="center">
              <BaseTableSearchField
                v-model="filter.search"
                @triggerSearch="retrieveRoles"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="roles"
            :loading="isLoading"
            :options.sync="options"
            :server-items-length="meta.total"
          >
            <template #[`item.action`]="{ item }">
              <v-row no-gutters justify="end">
                <v-col cols="auto" :class="iconMargin" align-self="center">
                  <ShowRoleDetailIcon :roleId="item.id" />
                </v-col>

                <v-col cols="auto" :class="iconMargin" align-self="center">
                  <BaseHasPermission :permissionNames="['role.update']">
                    <RoleEdit :roleId="item.id" @reload="retrieveRoles" />
                  </BaseHasPermission>
                </v-col>

                <v-col cols="auto" :class="iconMargin" align-self="center">
                  <BaseHasPermission :permissionNames="['role.delete']">
                    <RoleDeleteAction :roleId="item.id" icon @deleted="retrieveRoles" />
                  </BaseHasPermission>
                </v-col>

                <v-col cols="auto" :class="iconMargin" align-self="center">
                  <BaseHasPermission :permissionNames="['role.update']">
                    <ShowRoleAssignPermissionsIcon :roleId="item.id" />
                  </BaseHasPermission>
                </v-col>

                <v-col cols="auto" :class="iconMargin" align-self="center">
                  <BaseHasPermission :permissionNames="['role.update']">
                    <ShowRoleAssignUsersIcon :roleId="item.id" />
                  </BaseHasPermission>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import ShowRoleDetailIcon from "@views/Restricted/UserManagement/Role/List/partials/ShowRoleDetailIcon.vue";
import ShowRoleAssignPermissionsIcon from "@views/Restricted/UserManagement/Role/List/partials/ShowRoleAssignPermissionsIcon.vue";
import ShowRoleAssignUsersIcon from "@views/Restricted/UserManagement/Role/List/partials/ShowRoleAssignUsersIcon.vue";
import RoleCreateDialog from "@components/Management/Role/RoleCreateDialog.vue";
import RoleDeleteAction from "@components/Management/Role/RoleDeleteAction.vue";
import RoleEdit from "@views/Restricted/UserManagement/Role/Update";
import hasPermission from "@mixins/hasPermission";

export default {
  name: "RoleList",

  components: {
    RoleDeleteAction,
    RoleCreateDialog,
    ModuleSubPage,
    ShowRoleDetailIcon,
    ShowRoleAssignPermissionsIcon,
    RoleEdit,
    ShowRoleAssignUsersIcon,
  },

  mixins: [hasPermission],

  data() {
    return {
      isLoading: false,
      roles: [],
      filter: {
        search: null,
      },
      options: {
        page: 1,
        perPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      meta: {
        total: 0,
      },
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.retrieveRoles();
      },
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("USER_MANAGEMENT.ROLES.LIST_HEADER_NAME"),
          value: "displayName",
          sortable: true,
        },

        {
          value: "action",
          sortable: false,
          align: "end",
        },
      ];
    },

    iconMargin() {
      return "mr-4 mr-md-0";
    },

    payload() {
      return {
        ...this.options,
        ...this.filter,
        perPage: this.options.perPage === -1 ? 0 : this.options.perPage,
      };
    },
  },

  methods: {
    async retrieveRoles() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.userManagement.listRoles(this.payload);
        this.roles = data.data;
        this.meta = data.meta;

        if (this.options.perPage === -1) {
          this.meta = {
            total: data.data.length,
          };
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
