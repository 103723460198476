import { mapActions } from "vuex";

export default {
  data() {
    return {
      validationErrors: {
        usersCount: 0,
        userGroupsCount: 0,
        rolesCount: 0,
      },
    };
  },

  computed: {
    hasErrors() {
      return (
        !!this.validationErrors.usersCount ||
        !!this.validationErrors.userGroupsCount ||
        !!this.validationErrors.rolesCount
      );
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    setValidationErrors(err) {
      if (err.response && err.response.status !== 500 && err.response.status !== 401) {
        const { data } = err.response;
        this.validationErrors = {
          ...this.validationErrors,
          ...data,
        };
      }
    },

    resetValidationErrors() {
      this.validationErrors = {
        usersCount: 0,
        userGroupsCount: 0,
        rolesCount: 0,
      };
    },
  },
};
