<template>
  <BaseAlert type="warning" class="mt-4">
    <div>
      <BaseText>
        {{ $t("USER_USER_MANAGEMENT.MANAGEMENT.VALIDATION.DELETE.CANNOT_DELETE") }}
      </BaseText>
    </div>
    <ul>
      <li v-if="!!errors.usersCount">
        <BaseText>
          {{
            $t("USER_USER_MANAGEMENT.MANAGEMENT.VALIDATION.DELETE.EXISTING_USERS", {
              count: usersCount,
            })
          }}
        </BaseText>
      </li>

      <li v-if="!!errors.userGroupsCount">
        <BaseText>
          {{
            $t("USER_USER_MANAGEMENT.MANAGEMENT.VALIDATION.DELETE.EXISTING_USER_GROUPS", {
              count: userGroupsCount,
            })
          }}
        </BaseText>
      </li>

      <li v-if="!!errors.rolesCount">
        <BaseText>
          {{
            $t("USER_USER_MANAGEMENT.MANAGEMENT.VALIDATION.DELETE.EXISTING_ROLES", {
              count: rolesCount,
            })
          }}
        </BaseText>
      </li>
    </ul>
  </BaseAlert>
</template>

<script>
export default {
  name: "UserManagementDeleteValidation",

  props: {
    errors: {
      type: Object,
      required: true,
      default: () => ({
        usersCount: 0,
        userGroupsCount: 0,
        rolesCount: 0,
      }),
    },
  },
};
</script>
