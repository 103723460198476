<template>
  <ConfirmationDialog
    title="USER_MANAGEMENT.ROLES.CREATE"
    label="USER_MANAGEMENT.ROLES.CREATE"
    labelCancel="USER_MANAGEMENT.ROLES.CREATE_BUTTON_LABEL_CANCEL"
    labelConfirm="USER_MANAGEMENT.ROLES.CREATE_BUTTON_LABEL_CREATE"
    v-model="showDialog"
    tertiary
    dialogWidth="640px"
    color="primary"
    @confirm="save"
    @cancel="resetForm"
    :loading="isLoading"
  >
    <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
      <RoleForm v-model="role" />
    </v-form>
  </ConfirmationDialog>
</template>

<script>
import ConfirmationDialog from "@components/ConfirmationDialog/";
import RoleForm from "@components/Management/Role/RoleForm.vue";
import formValidation from "@mixins/formValidation";

export default {
  name: "RoleCreateDialog",

  mixins: [formValidation],

  components: {
    RoleForm,
    ConfirmationDialog,
  },

  data() {
    return {
      showDialog: false,
      role: this.initRole(),
      isLoading: false,
    };
  },

  computed: {
    params() {
      return {
        name: this.role.name,
        displayName: this.role.displayName,
        description: this.role.description,
        level: this.role.level,
        isSelectable: this.role.isSelectable,
      };
    },
  },

  methods: {
    save() {
      this.validate();

      this.$nextTick(async () => {
        if (this.formValidation) {
          this.isLoading = true;
          try {
            await this.$axios.userManagement.createRole(this.params);

            this.showDialog = false;

            this.resetForm();

            this.$emit("reload");
          } catch (err) {
            console.error(err);
            this.setValidationErrors(err);
          } finally {
            this.isLoading = false;
          }
        }
      });
    },

    resetForm() {
      this.role = this.initRole();
      this.resetValidation();
    },

    initRole() {
      return {
        id: null,
        name: null,
        displayName: null,
        description: null,
        level: null,
        isSelectable: false,
      };
    },
  },
};
</script>
