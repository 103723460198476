<template>
  <v-tooltip left>
    <template #activator="{ on, attrs }">
      <v-btn icon @click="showEditRole" v-bind="attrs" v-on="on">
        <v-icon small v-text="`fas fa-key`" />
      </v-btn>
    </template>
    <span>{{ $t("USER_MANAGEMENT.ROLES.ACTION_ASSIGN_PERMISSIONS") }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "ShowRoleAssignPermissionsIcon",

  props: {
    roleId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    showEditRole() {
      this.$router.push({
        name: "ManagementRoleAssignPermissions",
        params: { roleId: this.roleId },
      });
    },
  },
};
</script>
